/* eslint-env browser */
import { html, LitElement, unsafeCSS, css, nothing} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import { spread } from '@open-wc/lit-helpers';


export class VTotalCountField extends LitElement {
  createRenderRoot() {
    return this;
  }

  connectedCallback() {
    super.connectedCallback()
    this.form = this.closest('form');

    if (this.form) {
      this.form.addEventListener('input', this.handleInputChange);
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    if (this.form) {
      this.form.removeEventListener('input', this.handleInputChange);
    }
  }

  updated() {
    this.calculate();
  }

  handleInputChange = () => {
    this.calculate();
  }

  calculate() {
    let rawExpression = this.getAttribute('expression');

    const targetInput = this.querySelector(`vf-field-input`);

    if (this.isValidExpression(rawExpression)) {
        let result = this.evaluateExpression(rawExpression);
        targetInput.value = result.toString();
    } else {
        targetInput.value = 'Error: Invalid expression';
    }
  }

  evaluateExpression(expr) {
    const sumPattern = /sum\((.*?)\)/;
    const match = expr.match(sumPattern);
    if(match) {
      const expandedExpression = this.expandExpression(match[1])
      return this.evaluateBasicExpression(expandedExpression)
    } else {
      return this.evaluateBasicExpression(expr)
    }
  }

  evaluateBasicExpression(expr) {
    // simple expressions
    let parts = expr.split('+').map(part => {
      if (part.includes('*')) {
        return part.split('*').reduce((acc, operand) => {
          const inputElement = this.form.querySelector(`input[name="${operand}"]`);
          const operandValue = parseFloat(inputElement?.value || "0");
          return acc * operandValue;
        }, 1);
      } else {
        const inputElement = this.form.querySelector(`input[name="${part}"]`);
        return parseFloat(inputElement?.value || "0");
      }
    });
    return parts.reduce((sum, val) => sum + val, 0);
  }

  expandExpression(expr) {
    const formsetName = expr.split('.')[0];
    const totalForms = this.form.querySelector(`input[name=formset-${formsetName}-TOTAL_FORMS]`).value;

    let expandedExpressions = [];
    for(let index=0; index<totalForms;index++) {
      const currentExpression = expr.replace(
        new RegExp(`${formsetName}\\.([a-zA-Z0-9_]+)`, 'g'), `formset-${formsetName}-${index}-$1`);
      expandedExpressions.push(currentExpression);
    }

    return expandedExpressions.join('+');
  }


  isValidExpression(expr) {
    if (!expr) return false;

    // This regex will validate sequences of alphanumeric characters (including underscores) separated by + or *,
    // and will also support sum() functions
    const basicExpr = '[a-zA-Z0-9_\.]+';
    const pattern = new RegExp(`^(sum\\(${basicExpr}(\\*${basicExpr})?\\)|${basicExpr}([+*]${basicExpr})*)$`);

    return pattern.test(expr);
}

  render() {
    const attrs = Array.from(this.attributes).reduce((acc, attr) => {
      if(attr.name != 'expression' || attr.name != 'round') {
        acc[attr.name] = attr.value;
      }
      return acc;
    }, {});

    return html`
      <vf-field-input ${spread(attrs)} readonly="readonly" value="0"></vf-field-input>
    `
  }

}

customElement('vf-field-total-counter')(VTotalCountField);

