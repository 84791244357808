/* eslint-env browser */
import {customElement} from 'solid-element';
import {createEffect} from 'solid-js';

const defaultProps = {
  apiUrl: undefined,
  csrfToken: undefined,
};


const VSwagger = customElement('vf-swagger', defaultProps, (props, {element}) => {
  let control;

  Object.defineProperty(element, 'renderRoot', {
    value: element,
  });

  const start = () => {
    if(!window.SwaggerUIBundle) {
      setTimeout(() => start(), 300)
    } else {
      new window.SwaggerUIBundle({
        'url': props.apiUrl,
        'domNode': control,
        'docExpansion': 'none',
        'requestInterceptor': (request) => {
          request.headers['X-CSRFToken'] = props.csrfToken;
          return request;
        },
      });
    }
  }

  createEffect(() => start());

  return <div ref={control}></div>;
});


export {
  VSwagger,
};
