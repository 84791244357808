import {customElement} from 'solid-element';
import {onCleanup, createEffect} from 'solid-js';
import {MDCCheckbox} from '@material/checkbox';
import cc from 'classcat';

import './index.scss';


const defaultProps = {
  'columns': 1,
  'disabled': false,
  'error': undefined,
  'helpText': undefined,
  'id': undefined,
  'inline': undefined,
  'label': undefined,
  'name': undefined,
  'optgroups': undefined,
  'required': false,
  'value': undefined,
};


const VCheckboxSelectField = customElement('vf-field-checkbox-select', defaultProps, (props, {element}) => {
  Object.defineProperty(element, 'renderRoot', {
    value: element,
  });

  const checkboxes = [];

  createEffect(() => {
    element.querySelectorAll('.mdc-checkbox').forEach((control) => {
      checkboxes.push(new MDCCheckbox(control));
    });
  });

  onCleanup(() => {
    checkboxes.forEach((checkbox) => {
      checkbox.destroy();
    });
  });

  const chunk = (arr, chunkSize) => {
    const result = [];
    for (let i=0, len=arr.length; i<len; i+=chunkSize) {
      result.push(arr.slice(i, i+chunkSize));
    }
    return result;
  };

  const item = (prop, options) => {
    return (
      <div class='vf-field__checkbox-container'>
        <div class="mdc-touch-target-wrapper">
          <div class="vf-field__control mdc-form-field">
            <div
              class={
                cc({
                  'mdc-checkbox': true,
                  'mdc-checkbox--disabled': !!props.disabled,
                })
              }>
              <input
                checked={ options.selected }
                class="mdc-checkbox__native-control"
                disabled={ !!props.disabled }
                name={ options.name }
                required={ !!props.required }
                type="checkbox"
                value={ options.value }
                { ...options.attrs }/>
              <div class="mdc-checkbox__background">
                <svg class="mdc-checkbox__checkmark"
                  viewBox="0 0 24 24">
                  <path class="mdc-checkbox__checkmark-path"
                    fill="none"
                    d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                </svg>
                <div class="mdc-checkbox__mixedmark"></div>
              </div>
              <div class="mdc-checkbox__ripple"></div>
            </div>
            <label for={ options.attrs.id }>{ options.label }</label>
          </div>
        </div>
      </div>
    );
  };

  const items = (props) => {
    const items = [];
    const wrapperClasses = `mdc-layout-grid__cell mdc-layout-grid__cell--span-${ Math.ceil(12/props.columns)}-desktop`;

    for (const group of chunk(props.optgroups, Math.ceil(props.optgroups.length/props.columns))) {
      items.push(
          <div class={ wrapperClasses }>
            {
              group.map((groupData)=> item(props, groupData.options))
            }
          </div>,
      );
    }
    return items;
  };

  return (
    <div class={
      cc({
        'vf-field__row': true,
        'vf-checkbox-select--inline': !!props.inline,
        'vf-checkbox-select--invalid': !! props.error,
      })}
    >
      <label class="vf-checkbox-select__label mdc-floating-label">{ props.label }</label>
      <div class="vf-radio-select__control">
        <div class="mdc-layout-grid">
          <div class="mdc-layout-grid__inner">
            { items(props) }
          </div>
        </div>
      </div>
      { props.helpText || props.error ?
        <div class="mdc-text-field-helper-line">
          <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent">
            { props.error || props.helpText }
          </div>
        </div> : '' }
    </div>
  );
});

export {
  VCheckboxSelectField,
};
