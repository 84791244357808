import {customElement} from 'solid-element';
import {createEffect, onCleanup} from 'solid-js';
import { JSONEditor } from 'vanilla-jsoneditor/standalone.js'

// import './index.scss';


const defaultProps = {
  'cols': undefined,
  'disabled': false,
  'error': undefined,
  'helpText': undefined,
  'id': undefined,
  'label': undefined,
  'name': undefined,
  'required': false,
  'rows': undefined,
  'value': undefined,
};


const VJSONField = customElement('vf-field-json', defaultProps, (props, {element}) => {
  let editor;
  let textarea;
  let jsonEditor;

  Object.defineProperty(element, 'renderRoot', {
    value: element,
  });    

  createEffect(() => {
    jsonEditor = new JSONEditor({'target': editor, 'props': {
        'content': { json: props.value || '' },
        'onChange': (updatedContent, previousContent, { contentErrors, patchResult }) => {
          textarea.value = JSON.stringify(updatedContent)
        }
    }})
  });

  onCleanup(() => {
    if (jsonEditor) {
        jsonEditor.destroy();
        jsonEditor=null;
    }
  });

  const inputId = props.id + '_control';

  return (
      <div class="vf-field__row">
        <div class={!!props.error ? 'mdc-text-field--invalid': ''}>
          <textarea
            style="display:none"
            ref={textarea}
            cols={ props.cols }
            disabled={ !!props.disabled }
            id={ inputId }
            name={ props.name }
            required={ !!props.required }
            rows={ props.rows }
            aria-label={ props.label }
            type="hidden">{JSON.stringify(props.value)}</textarea>
          <div ref={editor}></div>
        </div>
        { props.helpText || props.error ?
        <div class="mdc-text-field-helper-line">
            <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent">
            { props.error || props.helpText }
            </div>
        </div> : '' }
      </div>
  )
});


export {
    VJSONField,
};
  