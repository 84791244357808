/* eslint-env browser */
import './index.scss';

export class VFormset extends HTMLElement {
  connectedCallback() {
    setTimeout(() => {
      this._name = this.getAttribute('name');

      this._formsContainerEl = this.querySelector('.vf-form__formset-forms');
      this._button = this.querySelector('.vf-from__formset-button');
      this._button.addEventListener('click', this.onAdd);
      this._formTemplate = this.querySelector('script[type=form-template]');

      this._totalEl = this.querySelector(`[name=formset-${this._name}-TOTAL_FORMS]`);
      this._initialEl = this.querySelector(`[name=formset-${this._name}-INITIAL_FORMS]`);
      this._minNumEl = this.querySelector(`[name=formset-${this._name}-MIN_NUM_FORMS]`);
      this._maxNumEl = this.querySelector(`[name=formset-${this._name}-MAX_NUM_FORMS]`);
    });
  }

  disconnectedCallback() {
    this._button.removeEventListener('onclick', this.onAdd);
  }

  onAdd = (event) => {
    event.preventDefault();

    const totalForms = parseInt(this._totalEl.value);

    if (totalForms >= parseInt(this._maxNumEl.value)) {
      window.dispatchEvent(new CustomEvent('vf-snackbar:show', {
        'detail': {message: 'MAX_NUM_FORMS reached'},
      }));

      return;
    }


    this._totalEl.value = totalForms + 1;

    const formHtml = this._formTemplate.innerText.replace(new RegExp('__prefix__', 'g'), totalForms);
    const formEl = new DOMParser().parseFromString(formHtml, 'text/html').body.children[0];
    this._formsContainerEl.append(formEl);
    this.closest('form').dispatchEvent(new Event('input', {'bubbles': true,'cancelable': true}));
  }
}
