/* eslint-env browser */
import SVGInject from '@iconfu/svg-inject';

export class VImage extends HTMLElement {
  connectedCallback() {
    setTimeout(() => this.reload(), 100);
  }

  reload() {
    const image = this.querySelector('img');
    const image_url = new URL(image.src);
    image_url.searchParams.set('_reload', Date.now());

    var newImage = new Image();
    newImage.onload = () => {
      image.src = newImage.src;
      setTimeout(() => SVGInject(image), 100);
    }
    newImage.src = image_url.href;
  }
}
