/* eslint-env browser */
import {createEffect, onCleanup} from 'solid-js';
import {customElement} from 'solid-element';
// import cc from 'classcat';

import './index.scss';


const defaultProps = {
  'disabled': false,
  'error': undefined,
  'helpText': undefined,
  'id': undefined,
  'label': undefined,
  'name': undefined,
  'required': false,
  'value': '',
  'options': {},
};


const VEditorField = customElement('vf-field-editor', defaultProps, (props, {element}) => {
  let trixEditor;

  Object.defineProperty(element, 'renderRoot', {
    value: element,
  });

  createEffect(() => {
    trixEditor.setAttribute('input', props.id + '_control');
    if(trixEditor.editor) {
      trixEditor.editor.loadHTML(props.value);
    }
  });

  onCleanup(() => {
  });

  const inputId = props.id + '_control';

  return (
    <div class="vf-field__row">
      <div class={!!props.error ? 'mdc-text-field--invalid': ''}>
        <input
          cols={ props.cols }
          disabled={ !!props.disabled }
          id={ inputId }
          name={ props.name }
          required={ !!props.required }
          rows={ props.rows }
          aria-label={ props.label }
          value={props.value}
          type="hidden"
        ></input>
        <trix-editor ref={trixEditor}></trix-editor>
      </div>
      { props.helpText || props.error ?
      <div class="mdc-text-field-helper-line">
        <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent">
          { props.error || props.helpText }
        </div>
      </div> : '' }
    </div>
  );
});

export {
  VEditorField,
};


// window.addEventListener('load', () => {
//   window.customElements.define('vf-field-editor', MediumEditorElement);
// });


// <div ref={mediumEl} class="mdc-typography"></div>
// <textarea
//   ref={textAreaEl}
//   style="display:none"
//   class="mdc-text-field__input"
//   cols={ props.cols }
//   disabled={ !!props.disabled }
//   id={ props.id + '_control' }
//   name={ props.name }
//   required={ !!props.required }
//   rows={ props.rows }
//   aria-label={ props.label }>{ props.value }</textarea>
// { props.helpText || props.error ?
//   <div class="mdc-text-field-helper-line">
//     <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent">
//       { props.error || props.helpText }
//     </div>
//   </div> : '' }
