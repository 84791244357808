/* eslint-env browser */

import {throttle, debounce} from 'throttle-debounce';
import {customElement} from 'solid-element';
import {onCleanup, createEffect, createSignal} from 'solid-js';
import {menu, floatingLabel, notchedOutline} from 'material-components-web';
import cc from 'classcat';

import './index.scss';

const defaultProps = {
  'autofocus': undefined,
  'disabled': false,
  'error': undefined,
  'helpText': undefined,
  'id': undefined,
  'label': undefined,
  'leadingIcon': undefined,
  'maxlength': undefined,
  'minlength': undefined,
  'name': undefined,
  'placeholder': undefined,
  'required': false,
  'step': undefined,
  'trailingIcon': undefined,
  'type': 'text',
  'initial': [],
};

const VMultiAutocompleteField = customElement('vf-field-autocomplete-multi', defaultProps, (props, {element}) => {
  let inputEl;
  let labelEl;
  let menuEl;
  let outlineEl;

  let mdcMenu;
  let mdcLabel;
  let mdcOutline;

  Object.defineProperty(element, 'renderRoot', {
    value: element,
  });

  const [suggestions, setSuggestions] = createSignal([]);

  const [selected, setSelected] = createSignal(props.initial);


  createEffect(() => {
    if (!mdcLabel) {
      mdcLabel = new floatingLabel.MDCFloatingLabel(labelEl);
    }
    if (!mdcOutline) {
      mdcOutline = new notchedOutline.MDCNotchedOutline(outlineEl);
      notchOutline(selected().length);
    }
    if (!mdcMenu) {
      mdcMenu = new menu.MDCMenu(menuEl);
      mdcMenu.listen('MDCMenu:selected', (event)=>{
        setTimeout(() => {
          setSelection(event.detail.index);
          inputEl.focus();
        });
      });
    }
  });

  onCleanup(() => {
    if(mdcLabel) {
      mdcLabel.destroy();
    }
    if(mdcOutline) {
      mdcOutline.destroy();
    }
    if(mdcMenu) {
      mdcMenu.destroy();
    }
  });

  const notchOutline = (openNotch) => {
    if (openNotch) {
      mdcOutline.notch(mdcLabel.getWidth() * 0.75);
    } else {
      mdcOutline.closeNotch();
    }
  };

  const fetchSuggestions = async (value) => {
    const result = await fetch('', {
      method: 'OPTIONS',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-Request-Autocomplete': `field=${props.name}&query=${encodeURIComponent(value)}`,
      },
    });
    mdcMenu.open = true;

    const data = await result.json();

    const selectedItems = selected();
    const newSuggestions = data.suggestions.filter((value, index, arr) => {
      for (const item of selectedItems) {
        if (item.data.id == value.data.id) {
          return false;
        }
      }
      return true;
    });

    setSuggestions(newSuggestions);
    mdcMenu.selectedIndex = 0;
  };

  const throttledFetch = throttle(500, fetchSuggestions);
  const debouncedFetch = debounce(200, fetchSuggestions);

  const autocompleteFetchSuggestions = () => {
    const value = inputEl.value;
    if (value.length < 5) {
      debouncedFetch(value);
    } else {
      throttledFetch(value);
    }
  };

  const setSelection = (selectedIndex)=> {
    const selectedSuggestion = suggestions()[selectedIndex];

    mdcMenu.open = false;
    // mdcMenu.list_.singleSelection = true;
    // mdcMenu.list_.selectedIndex = -1;
    inputEl.value = '';

    setTimeout(() => {
      setSelected([...selected(), selectedSuggestion]);
    }, 50);
  };

  const onKeyDown = async (event) => {
    if (event.key == 'ArrowUp') {
      // todo scroll
      mdcMenu.selectedIndex = Math.min(Math.max(0, mdcMenu.selectedIndex - 1), mdcMenu.items.length);
      event.preventDefault();
    } else if (event.key == 'ArrowDown') {
      mdcMenu.selectedIndex = Math.min(mdcMenu.items.length, mdcMenu.selectedIndex+1);
      event.preventDefault();
    } else if (event.key == 'Enter') {
      if (mdcMenu.open) {
        setSelection(mdcMenu.selectedIndex);
        event.preventDefault();
      }
    } else if (event.key == 'Escape') {
      mdcMenu.open = false;
      mdcMenu.list_.singleSelection = true;
      mdcMenu.list_.selectedIndex = -1;
    }
  };

  const onKeyUp = async (event) => {
    const ignoredKeys = [
      'ArrowUp',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'Enter',
      'Escape',
      'Tab',
    ];
    if (!ignoredKeys.includes(event.key)) {
      // dataEl.value = '';
      autocompleteFetchSuggestions();
    }
  };

  const onFocus = (event) => {
    mdcLabel.float(true);
    notchOutline(true);
  };

  const onFocusOut = (event) => {
    if (!selected().length) {
      mdcLabel.float(false);
      notchOutline(false);
    }
  };

  const removeSelected = (event) => {
    if (props.disabled) return;
    const selectedItems = selected().filter((value) => {
      return value.data.id != event.currentTarget.dataset.value;
    });
    setSelected(selectedItems);
  };

  const items = (suggestions) => {
    const result = [];
    for (const [index, groupData] of suggestions.entries()) {
      result.push(
          <li class={cc({
            'mdc-list-item': true,
            'mdc-list-item--with-one-line': true,
            'mdc-list-item--selected': index==0,
          })} role="menuitem" data-value={ groupData.data.id }>
            <span class="mdc-list-item__ripple"></span>
            <span class="mdc-list-item__text mdc-list-item__content">{groupData.value}</span>
          </li>,
      );
    }
    return result;
  };

  const chips = (selected) => {
    const result = [];
    for (const groupData of selected) {
      result.push(
          <div class="mdc-chip" role="row" data-value={ groupData.data.id } onClick={removeSelected}>
            { props.disabled ? '' : <div class="mdc-chip__ripple"></div> }
            <span role="gridcell">
              <span role="button" tabindex="0" class="mdc-chip__primary-action">
                <span class="mdc-chip__text">{groupData.value}</span>
              </span>
            </span>
          </div>,
      );
    }
    return result;
  };

  const hiddenInputs = (selected) => {
    const result = [];
    for (const groupData of selected) {
      result.push(
          <input
            name={ props.name }
            type="hidden"
            value={ groupData.data.id }/>,
      );
    }
    return result;
  };

  return (
    <div class="vf-field__row" style="min-height: 76px;">
      <label
        style="align-items: start;will-change:initial"
        class={ cc({
          'mdc-text-field': true,
          'mdc-text-field--outlined': true,
          'mdc-text-field--textarea': true,
          'mdc-text-field--invalid': !! props.error,
          'mdc-text-field--disabled': props.disabled,
        }) }
      >
        <span class="mdc-notched-outline" ref={outlineEl}>
          <span class="mdc-notched-outline__leading"></span>
          <span class="mdc-notched-outline__notch">
            <span
              class={cc({
                'mdc-floating-label': true,
                'mdc-floating-label--float-above': selected().length})}
              style="margin-top:-3px"
              ref={labelEl}
            >
              { props.label }
            </span>
          </span>
          <span class="mdc-notched-outline__trailing"></span>
        </span>
        <div class="mdc-chip-set" role="grid">
          { chips(selected()) }
          { hiddenInputs(selected()) }
          <div>
            <input
              style="width:auto;flex-grow:1;margin-top: 8px;margin-bottom: 8px;min-width:150px"
              type="text"
              class="mdc-text-field__input"
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
              onFocus={onFocus}
              onFocusOut={onFocusOut}
              disabled={props.disabled}
              ref={inputEl}></input>
            <div class="mdc-menu-surface--anchor">
              <div class="mdc-menu mdc-menu-surface mdc-menu-surface--fullwidth" ref={menuEl}>
                <ul class="mdc-list" role="listbox">
                  { items(suggestions()) }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </label>
      { props.helpText || props.error ?
        <div class="mdc-text-field-helper-line">
          <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent">
            { props.error || props.helpText }
          </div>
        </div> : '' }
    </div>
  );
});


export {
  VMultiAutocompleteField,
};
