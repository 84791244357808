/* eslint-env browser */
export class VFNetwork extends HTMLElement {
  connectedCallback() {
    const data = new vis.network.dotparser.DOTToGraph(this.getAttribute('dot'));
    const options = {
      layout: {
        hierarchical: {
          direction: 'LR',
          sortMethod: 'directed',
          levelSeparation: 100,
        },
      },
    };
    this.network = new window.vis.Network(this, data, options);
  }

  disconnectedCallback() {
    this.network.destroy();
  }
}
